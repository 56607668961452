<template>
  <Head v-if="costMin">
    <title>
      Купить квартиру-студию в новостройке на Ботаническом саду в Москве от
      застройщика | ЖК VERY
    </title>
    <meta
      name="description"
      :content="
        'Продажа новых квартир-студий в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:title"
      content="Купить квартиру-студию в новостройке на Ботаническом саду в Москве от застройщика | ЖК VERY"
    />
    <meta
      property="og:description"
      :content="
        'Продажа новых квартир-студий в ЖК VERY от застройщика ГК ОСНОВА на ул Ботаническая 29 в Москве (СВАО, р-н Марфино) рядом с метро Фонвизинская/Петровско-Разумовская по цене от ' +
        $filters.priceFormat(costMin)
      "
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>

  <FlatsSearch h1="Квартиры-студии" pre-rooms="0" @costMin="onCostMinEmits">
    <template #text>
      <div class="text mt-5">
        <p>
          Хотите жить в&nbsp;центре мегаполиса, но&nbsp;при этом наслаждаться
          чистым воздухом, изумительными видами на&nbsp;бескрайние зеленые
          просторы и&nbsp;прогулками под сенью деревьев? Не&nbsp;отказывайте
          себе в&nbsp;этих удовольствиях и&nbsp;купите студию в&nbsp;20&nbsp;м
          от&nbsp;Главного ботанического сада РАН.
        </p>
        <p>
          Эко-квартал Very&nbsp;&mdash; это функциональные и&nbsp;уютные
          квартиры-студии у&nbsp;ботанического сада, которые изменят ваше
          представление о&nbsp;комфортабельной жизни. Мы&nbsp;создали уникальный
          квартал, в&nbsp;котором&nbsp;77% всей свободной
          территории&nbsp;&mdash; это места для отдыха, прогулок, игр.
          В&nbsp;общем под зеленую зону отведена площадь, которую можно сравнить
          с&nbsp;4&nbsp;футбольными полями. Вас поразит удивительное сочетание
          города и&nbsp;природы. Высаженные кустарники и&nbsp;деревья создают
          ощущение присутствия в&nbsp;самом чистом и&nbsp;сказочном лесу,
          но&nbsp;благодаря продуманным решениям полностью сохраняется комфорт,
          привычный для городских жителей.
        </p>
        <p>
          Студия у&nbsp;Ботанического сада&nbsp;&mdash; это сочетание
          современных технологий строительства и&nbsp;премиальных материалов.
          Возможность каждый день созерцать сказочные виды из&nbsp;окна
          и&nbsp;проводить время на&nbsp;природе (и&nbsp;для этого не&nbsp;нужно
          куда-либо ехать, достаточно выйти из&nbsp;дома и&nbsp;прогуляться).
        </p>
        <h2 class="h4">
          ЖК Very предлагает покупателям студии у&nbsp;Ботанического сада РАН
          Москвы
        </h2>
        <p>
          Мы&nbsp;использовали абсолютно новую концепцию при планировке
          и&nbsp;оформлении квартир. Особенный акцент сделан
          на&nbsp;максимальный простор, достигнутый благодаря панорамным окнам
          от&nbsp;потолка до&nbsp;пола. Большое количество естественного света,
          удивительные пейзажи за&nbsp;окном создают воздушную и&nbsp;легкую
          атмосферу в&nbsp;помещении. Также активно внедрена технология умного
          дома, набирающая с&nbsp;каждым годом все большую популярность.
          Вы&nbsp;сможете с&nbsp;телефона в&nbsp;удаленном режиме управлять
          техникой, настроить различные системы внутри квартиры
          и&nbsp;в&nbsp;полной мере оценить достижения человечества в&nbsp;этом
          направлении.
        </p>
        <p>
          Покупка квартиры-студии в&nbsp;ЖК&nbsp;Very&nbsp;&mdash; это
          возможность жить в&nbsp;единении с&nbsp;природой (площадь прилегающих
          прогулочных зон составляет около 600&nbsp;га), в&nbsp;любой момент
          посетить территорию Главного и&nbsp;Малого Ботанических садов,
          прогуляться до&nbsp;ВДНХ или знаменитых парка-музея
          &laquo;Останкино&raquo;, Государственного центрального музея кино,
          центра &laquo;Космонавтики и&nbsp;авиации&raquo;,
          &laquo;Союзмультпарка&raquo;, Театра сказок и&nbsp;других
          достопримечательностей столицы.
        </p>
        <p>
          Для полной визуализации, получения всей интересующей информации
          рекомендуем посетить офис продаж ЖК&nbsp;VERY.
        </p>
      </div>
    </template>
  </FlatsSearch>
</template>

<script>
import FlatsSearch from "@/components/FlatsSearch.vue";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    FlatsSearch,
  },

  data() {
    return {
      costMin: null,
    };
  },
  methods: {
    onCostMinEmits(costMin) {
      this.costMin = costMin;
    },
  },
};
</script>
